import '../styles/main.scss';
import '../images/logo.png';
import '../images/quote-florence.jpg';
import '../images/quote-janna.jpg';
import '../images/quote-manouk.jpg';

// navigation

const navToggle = document.querySelector('.nav__toggle');
const navItems = document.querySelector('.nav__items');

navToggle.addEventListener('click', event => {
    navItems.classList.toggle('nav__items--active');
});

document.querySelectorAll('.nav__link--dropdown-toggle').forEach(navDropdownToggle => {
    const dropdown = navDropdownToggle.nextSibling;

    navDropdownToggle.addEventListener('click', event => {
        event.preventDefault();

        dropdown.classList.toggle('nav__dropdown--active')
    });
});

document.addEventListener('click', event => {
    const items = event.composedPath().filter(element => {
        if (!('classList' in element)) {
            return false;
        }

        return element.classList.contains('nav__link--dropdown-toggle');
    });

    if (1 === items.length) {
        return;
    }

    document.querySelectorAll('.nav__dropdown--active').forEach(navDropdown => {
        navDropdown.classList.remove('nav__dropdown--active');
    });
});

// carousel

let carouselPosition = 0;
const carouselItems = document.querySelectorAll('.carousel__item');
const carouselButtonPrev = document.querySelector('.carousel__button--prev');
const carouselButtonNext = document.querySelector('.carousel__button--next');
let carouselTimer;

carouselButtonPrev.addEventListener('click', event => {
    clearInterval(carouselTimer);

    move(carouselPosition > 0 ? carouselPosition - 1 : carouselItems.length - 1);
});

carouselButtonNext.addEventListener('click', event => {
    clearInterval(carouselTimer);

    moveNext();
});

function moveNext() {
    move(carouselPosition < carouselItems.length - 1 ? carouselPosition + 1 : 0);
}

function move(newPosition) {
    carouselItems[carouselPosition].classList.remove('carousel__item--active');

    carouselPosition = newPosition;
    carouselItems[carouselPosition].classList.add('carousel__item--active');
}

carouselTimer = setInterval(moveNext, 3000);
